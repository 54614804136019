import { injectable } from "inversify";
import { ApiInterface } from "./interfaces/api-interface";
import Http from "axios";
import { ApiUrlRequestBuilder } from "@/vue/infrastructure/api/api-url-request-builder";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfPaging from "@/vue/domain/paging/df-paging";
import * as qs from "qs";

@injectable()
class Api implements ApiInterface {
  private apiUrlBuilder: ApiUrlRequestBuilder = null;

  constructor() {
    this.apiUrlBuilder = new ApiUrlRequestBuilder();
    this.setDefaultHeaders();
  }

  public setDefaultHeaders() {
    const barearRefreshToken: string = Utils.localStorageGetItem(Utils.BEARER_REFRESH_TOKEN_KEY);
    const barearAccessToken: string = Utils.localStorageGetItem(Utils.BEARER_ACCESS_TOKEN_KEY);
    Http.defaults.headers.common = {};

    if (barearAccessToken != null) {
      Http.defaults.headers.common = {
        Authorization: `Bearer ${barearAccessToken}`,
      };
    }
  }

  public activeUser(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getRootEndPoint(`manage-action-token/${params.activationToken}`), {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public changeEmail(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getRootEndPoint(`private/email-change`),
        JSON.stringify({
          currentEmail: params.oldEmail,
          newEmail: params.newEmail,
          confirmNewEmail: params.confirmEmail,
          storeIdentifier: params.storeIdentifier,
        }),
        this.apiUrlBuilder.getJsonHeadersOption()
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public changePassword(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getRootEndPoint(`private/password-change`),
        JSON.stringify({
          oldPassword: params.oldPassword,
          newPassword: params.newPassword,
          confirmPassword: params.confirmPassword,
        }),
        this.apiUrlBuilder.getJsonHeadersOption()
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public deleteAccount(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getRootEndPoint(`private/account-delete`),
        JSON.stringify({
          password: params.password,
        }),
        this.apiUrlBuilder.getJsonHeadersOption()
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public downloadFile(params: any): any {
    return new Promise((resolve, reject) => {
      Http.get(`${Utils.getEndpointContextPath()}/api/files/${params.fileUniqueId}/${params.fileName}?download=true`, { responseType: "blob" })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public login(formData: FormData): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getOuthTokenPath(), formData, this.apiUrlBuilder.getHeaderForBasicAuthorization())
        .then((response) => {
          Http.defaults.headers.common = {
            Authorization: `Bearer ${response.data.access_token}`,
          };
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public logout(): any {
    return new Promise((resolve, reject) => {
      Http.delete(this.apiUrlBuilder.getOuthTokenPath(), this.apiUrlBuilder.getHeaderForRevokeAuthorization())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getStore(params: any): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${params.storeAlias}`), {})
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getStores(params: any): any {
    const latitude: number = params.geolocation ? params.geolocation.latitude : undefined;
    const longitude: number = params.geolocation ? params.geolocation.longitude : undefined;
    const filterQuery: string = params.searchQuery ? `&nameLike=${params.searchQuery}&cityLike=${params.searchQuery}` : "";
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores?page=${params.paging.number}${filterQuery}`), {
        params: { latitude: latitude, longitude: longitude },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getBrand(): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(""))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getIp(): any {
    return new Promise((resolve, reject) => {
      Http.get("https://ipinfo.io/json", this.apiUrlBuilder.getIpnifoHeadersOption())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getPromotions(storeAlias: string): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${storeAlias}/promotions`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProducts(promotionAlias: string, storeAlias: string, paging: DfPaging, query: string, contentAliases: Array<string>, customFilter: Array<string>): any {
    let queryParams: Array<string> = [`size=18`];
    queryParams = paging ? [...queryParams, `page=${paging.number}`] : queryParams;
    queryParams = contentAliases.length > 0 ? [...queryParams, ...contentAliases] : queryParams;
    queryParams = query ? [...queryParams, query] : queryParams;
    queryParams = customFilter ? [...queryParams, ...customFilter] : queryParams;
    const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${promotionAlias}/stores/${storeAlias}/products${encodeURI(queryString)}`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getBrandContents(typeCode: Array<string>): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`contents`), {
        params: {
          typeCode: typeCode || null,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getStoreContents(storeAlias: string, typeCode: Array<string>): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${storeAlias}/contents`), {
        params: {
          typeCode: typeCode || null,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getStoreOpenings(storeAlias: string, daysInterval: number): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${storeAlias}/openings`), {
        params: { daysInterval: daysInterval },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getStoreClosures(storeAlias: string, daysInterval: number): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${storeAlias}/closings`), {
        params: { daysInterval: daysInterval },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getPromotionContents(promotionAlias: string, storeAlias: string, typeCode: Array<string>): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`stores/${storeAlias}/promotions/${promotionAlias}/contents`), {
        params: {
          typeCode: typeCode || null,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProduct(promotionAlias: string, storeAlias: string, productAlias: string): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${promotionAlias}/stores/${storeAlias}/products/${productAlias}`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProductByCode(promotionAlias: string, storeAlias: string, productCode: string): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${promotionAlias}/stores/${storeAlias}/products-by-code/${productCode}`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProductByExternalData(params: any): any {
    // TODO: fix temporaneo da correggere
    params.productCode = params.productCode.startsWith("COD") || params.productCode.startsWith('GTN') ? params.productCode : `COD-${params.productCode}`;
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${params.promotionCode}/stores/${params.storeAlias}/sku/${params.productCode}`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProductByLink(params: any): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${params.promotionCode}/products/${params.productCode}?storeAlias=${params.storeAlias}`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProductsList(promotionAlias: string, storeAlias: string, productsUniqueId: Array<string>, limit: number): any {
    return new Promise((resolve, reject) => {
      Http.post(
        this.apiUrlBuilder.getEndPoint(`promotions/${promotionAlias}/stores/${storeAlias}/products-list`),
        JSON.stringify({
          uniqueIds: productsUniqueId,
          limit: limit,
        }),
        this.apiUrlBuilder.getJsonHeadersOption()
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getPromotionGroups(promotionAlias: string): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`promotions/${promotionAlias}/groups`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getUser(): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getRootEndPoint(`current-user`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getUserContents(params: any): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getRootEndPoint(`user-contents`), {
        params: { typeCode: params.typeCode, page: params.paging ? params.paging.number : undefined },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getProfileForm(): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getRootEndPoint(`private/profile-update `))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getRegistrationData(): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getRootEndPoint(`registration `))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getVoucher(): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`voucher`))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public getVoucherAll(params: any): any {
    return new Promise((resolve, reject) => {
      Http.get(this.apiUrlBuilder.getEndPoint(`voucher/all`), {
        params: { status: params.status, storeAlias: params.storeAlias },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public recoveryPassword(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getRootEndPoint(`password-forgotten`),
        JSON.stringify({
          email: params.email,
          storeIdentifier: params.storeIdentifier,
        }),
        this.apiUrlBuilder.getJsonHeadersOption()
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public refreshToken(formData: FormData): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getOuthTokenPath(), formData, this.apiUrlBuilder.getHeaderForBasicAuthorization())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public resetPassword(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getRootEndPoint(`manage-action-token/${params.resetPasswordToken}?action=reset-password`),
        JSON.stringify({
          newPassword: params.newPassword,
          confirmPassword: params.confirmPassword,
        }),
        this.apiUrlBuilder.getJsonHeadersOption()
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public selectVoucher(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getEndPoint(`voucher?action=${params.action}`),
        JSON.stringify({
          codes: params.codes,
        }),
        this.apiUrlBuilder.getJsonHeadersOption()
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public setLoyalGuruData(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getRootEndPoint(`loyal-guru/user-data`),
        JSON.stringify({
          oneSignalId: params.oneSignalId,
          app: Utils.enableLoyalGuruCoupon() && Utils.isMobile(),
        }),
        this.apiUrlBuilder.getJsonHeadersOption())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public setProfileData(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getRootEndPoint(`private/profile-update`), params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public setRegistrationData(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getRootEndPoint(`registration`), params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public sendFormMail(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getEndPoint(`formMail/${params.formMailAlias}`), params.formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public subscribeNewsletter(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getEndPoint(`sendy/manage`), JSON.stringify(params), this.apiUrlBuilder.getJsonHeadersOption())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public unsubscribeNewsletter(params: any): any {
    return new Promise((resolve, reject) => {
      Http.post(this.apiUrlBuilder.getEndPoint(`sendy/manage`), JSON.stringify(params), this.apiUrlBuilder.getJsonHeadersOption())
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export { Api };
