import StoreStateInterface from "./store-state-interface";

const storeState: StoreStateInterface = {
  stores: [],
  brand: null,
  promotions: [],
  currentStore: null,
  currentPromotion: null,
  products: [],
  productsPaging: null,
  storesPaging: null,
  shoppingList: [],
  activeTags: [],
  customFiltersQuery: [],
  activeCategory: null,
  promotionContents: [],
  storeContents: [],
  promotionGroups: [],
  cachedProducts: [],
  storeOpenings: [],
  storeClosures: [],
  brandContents: [],
  productsQueryValue: null,
  storedPushesList: [],
  scrollbarHidden: false,
  initialRoute: null,
  history: [],
  historyIndex: 0,
  clientNotification: true,
  geolocationEnabled: null,
  promotionsLoaded: false,
  promotionsPopupsWhatched: [],
  user: null,
  loginMail: null,
  showLogin: false,
  couponList: [],
};

export default storeState;
