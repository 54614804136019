import DfCode from "@/vue/domain/brand/df-code";

export default class DfCoupon {
  static STATUS_AVAILABLE = "AVAILABLE";
  static STATUS_EXPIRED = "EXPIRED";
  static STATUS_PENDING = "PENDING";
  static STATUS_CANCELLED = "CANCELLED";
  static STATUS_REDEEMED = "REDEEMED";
  static STATUS_PRINTED = "PRINTED";
  static STATUS_AVAILABLE_TO_PRINT = "AVAILABLE_TO_PRINT";
  static DISCOUNT_CASH = "CASH";
  static DISCOUNT_PERCENT = "PERCENT";
  static COUPON_TYPE_MIN_UNITS = "min_units";
  static ACTION_SELECT = "SELECT";
  static ACTION_DESELECT = "DESELECT";

  private _code: string;
  private _name: string;
  private _description: string;
  private _image: string;
  private _availableFrom: string;
  private _availableTo: string;
  private _status: string;
  private _discountType: string;
  private _discount: number;
  private _availableRedemptions: number;
  private _frozenRedemptions: number;
  private _maxRedemptions: number;
  private _selected: boolean;
  private _productCodes: Array<DfCode>;
  private _affectation: string;
  private _minUnits: number;
  private _maxUnits: number;
  private _minMoney: number;
  private _maxMoney: number;
  private _cumulative: boolean;
  private _couponType: string;
  private _hasPoints: boolean;
  private _points: number;
  private _pointsMoney: number;
  private _stores: Array<string>;
  private _checked = false;
  private _checkDisabled = false;

  constructor(
    code: string,
    name: string,
    description: string,
    image: string,
    availableFrom: string,
    availableTo: string,
    status: string,
    discountType: string,
    discount: number,
    availableRedemptions: number,
    frozenRedemptions: number,
    maxRedemptions: number,
    selected: boolean,
    productCodes: Array<DfCode>,
    affectation: string,
    minUnits: number,
    maxUnits: number,
    minMoney: number,
    maxMoney: number,
    cumulative: boolean,
    couponType: string,
    hasPoints: boolean,
    points: number,
    pointsMoney: number,
    stores: Array<string>,
  ) {
    this._code = code;
    this._name = name;
    this._description = description;
    this._image = image;
    this._availableFrom = availableFrom;
    this._availableTo = availableTo;
    this._status = status;
    this._discountType = discountType;
    this._discount = discount;
    this._availableRedemptions = availableRedemptions;
    this._frozenRedemptions = frozenRedemptions;
    this._maxRedemptions = maxRedemptions;
    this._selected = selected;
    this._productCodes = productCodes;
    this._affectation = affectation;
    this._minUnits = minUnits;
    this._maxUnits = maxUnits;
    this._minMoney = minMoney;
    this._maxMoney = maxMoney;
    this._cumulative = cumulative;
    this._couponType = couponType;
    this._hasPoints = hasPoints;
    this._points = points;
    this._pointsMoney = pointsMoney;
    this._stores = stores;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get image(): string {
    return this._image;
  }

  set image(value: string) {
    this._image = value;
  }

  get availableFrom(): string {
    return this._availableFrom;
  }

  set availableFrom(value: string) {
    this._availableFrom = value;
  }

  get availableTo(): string {
    return this._availableTo;
  }

  set availableTo(value: string) {
    this._availableTo = value;
  }

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }

  get discountType(): string {
    return this._discountType;
  }

  set discountType(value: string) {
    this._discountType = value;
  }

  get discount(): number {
    return this._discount;
  }

  set discount(value: number) {
    this._discount = value;
  }

  get availableRedemptions(): number {
    return this._availableRedemptions;
  }

  set availableRedemptions(value: number) {
    this._availableRedemptions = value;
  }

  get frozenRedemptions(): number {
    return this._frozenRedemptions;
  }

  set frozenRedemptions(value: number) {
    this._frozenRedemptions = value;
  }

  get maxRedemptions(): number {
    return this._maxRedemptions;
  }

  set maxRedemptions(value: number) {
    this._maxRedemptions = value;
  }

  get selected(): boolean {
    return this._selected;
  }

  set selected(value: boolean) {
    this._selected = value;
  }

  get productCodes(): Array<DfCode> {
    return this._productCodes;
  }

  set productCodes(value: Array<DfCode>) {
    this._productCodes = value;
  }

  get affectation(): string {
    return this._affectation;
  }

  set affectation(value: string) {
    this._affectation = value;
  }

  get minUnits(): number {
    return this._minUnits;
  }

  set minUnits(value: number) {
    this._minUnits = value;
  }

  get maxUnits(): number {
    return this._maxUnits;
  }

  set maxUnits(value: number) {
    this._maxUnits = value;
  }

  get minMoney(): number {
    return this._minMoney;
  }

  set minMoney(value: number) {
    this._minMoney = value;
  }

  get maxMoney(): number {
    return this._maxMoney;
  }

  set maxMoney(value: number) {
    this._maxMoney = value;
  }

  get cumulative(): boolean {
    return this._cumulative;
  }

  set cumulative(value: boolean) {
    this._cumulative = value;
  }

  get couponType(): string {
    return this._couponType;
  }

  set couponType(value: string) {
    this._couponType = value;
  }

  get hasPoints(): boolean {
    return this._hasPoints;
  }

  set hasPoints(value: boolean) {
    this._hasPoints = value;
  }

  get points(): number {
    return this._points;
  }

  set points(value: number) {
    this._points = value;
  }

  get pointsMoney(): number {
    return this._pointsMoney;
  }

  set pointsMoney(value: number) {
    this._pointsMoney = value;
  }

  get stores(): Array<string> {
    return this._stores;
  }

  set stores(value: Array<string>) {
    this._stores = value;
  }

  get checked(): boolean {
    return this._checked;
  }

  set checked(value: boolean) {
    this._checked = value;
  }

  get checkDisabled(): boolean {
    return this._checkDisabled;
  }

  set checkDisabled(value: boolean) {
    this._checkDisabled = value;
  }
}
